import MenuContainer_ClassicComponent from '@wix/thunderbolt-elements/src/components/MenuContainer/viewer/skinComps/Classic/Classic.skin';
import MenuContainer_ClassicController from '@wix/thunderbolt-elements/src/components/MenuContainer/viewer/MenuContainer.controller';


const MenuContainer_Classic = {
  component: MenuContainer_ClassicComponent,
  controller: MenuContainer_ClassicController
};


export const components = {
  ['MenuContainer_Classic']: MenuContainer_Classic
};

